@import 'primereact/resources/themes/saga-orange/theme.css';
@import 'primereact/resources/primereact.min.css';

body {
  font-family: 'Inter';
  height: 100vh;
  background-color: #f4f5f9;
}

.containerBlock {
  height: 100%;
}

.menuContainer {
    background-color: white;
}

.mainContainer {
    padding: 0 30px;
    padding-top: 1.25rem;
}

.transition {
  transition: all 0.5s ease;
}

body {
  margin: 0px;
  height: 100vh;
}

.star {
  color: red;
  padding: 2px;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  color: #F97316 !important;
}
.p-dialog .p-dialog-footer {
  text-align: start;
}

.p-confirm-dialog-accept span, .p-confirm-dialog-reject span{
  display: none;
}
.p-confirm-dialog-reject {
  color: black;
  background-color: #faba15;
  border: none;
  font-weight: normal;
}
.p-confirm-dialog-accept {
  color: #64748b;
  background-color: white;
  border: 0.5px solid #cfcfcf ;
  font-weight: normal;
}
.p-confirm-dialog-accept:after{
  content: "OK";
}
.p-confirm-dialog-reject:after {
  content: "Cancel";
}
.p-button {
  box-shadow: none !important;
}

.p-datatable-footer {
  background-color: white;
  border: none;
  padding: 0px;
}

.p-paginator {
  justify-content: end;
}

.p-datatable .p-column-header-content {
  font-size: 16px;
  font-weight: 500;
  color: #334155;
}

.p-inputtext:focus, .p-dropdown:focus {
  box-shadow: none;
  border-color: none;
}

.p-tabview {
  border: 1px solid #e2e8f0;
}
.p-tabview li{
  width: calc(100% / 3);
  background-color: #eab308;
}
.p-tabview-panels {
  padding: 22px 16px;
}
.p-inputnumber-buttons-stacked .p-inputnumber-input {
  width: 100%;
}
.p-inputnumber-button {
  background-color: white;
  color: #334155;
  border: 1px solid #d1d5db;
}
.p-checkbox .p-checkbox-box.p-highlight, .p-radiobutton .p-radiobutton-box.p-highlight{
  border-color: #eab308;
  background-color: #eab308;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  box-shadow: none;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
  border-color: #eab308;
}
/* .p-dialog{
  width: 75vw !important;
} */
.p-button.p-button-icon-only{
  width: 2rem;
}
.p-dialog .p-dialog-header .p-dialog-title{
  font-size: 24px;
}
.p-dialog .p-dialog-header{
  padding: 1.9rem;
}
.p-progressbar-determinate .p-progressbar-label {
  display: none;
}
.p-datatable .p-datatable-tbody > tr > td{
  padding: 1px 12px;
  font-size: 1rem;
}
.p-datatable .p-datatable-header{
  padding: 1rem;
}
.p-datatable .p-datatable-thead > tr > th{
  padding: 4px 1rem;
}
.p-tag.p-tag-warning{
  background-color: white;
  color: black;
}
.p-radiobutton .p-radiobutton-box{
  background: white;
}
.p-radiobutton-box.p-highlight .p-radiobutton-icon , .p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
  background-color: #eab308 !important;
}

.p-menubar{
  border: none;
}
.p-menubar .p-submenu-icon{
  display: none;
}
.p-menubar-root-list > .p-menuitem-active > .p-submenu-list{
  width: max-content;
  z-index: 1000;
}
.p-tag.p-tag-success{
  background-color: #22C55E;
  color: #ffffff;
  border-radius: 32px;
  font-weight: 500;
}
.p-tag.p-tag-info{
  background-color: #0EA5E9;
}
.p-tag.p-tag-danger{
  background-color: #EF4444;
  border-radius: 32px;
  font-weight: 500;
}
.p-menubar, .p-menubar:hover{
  background-color: #f4f5f9;
}
@media screen and (min-width: 992px){
.processData {
    flex: 0 0 auto;
    width: 23.3333% !important; 
}
.Ponumber{
  margin-left: 3rem;
}
}
@media (max-width: 1240px) and (min-width: 786px){
.processData {
  width: 21.3333% !important; 
}
}
.p-sortable-column .p-sortable-column-icon {
  visibility: hidden; 
}
.p-sortable-column:hover .p-sortable-column-icon {
  visibility: visible; 
  color: #eab308;
}
.p-datatable-thead{
  height: 54px;
}
.bg-lightYellow{
  background-color: #fefbf3;
}


/* @media screen and (min-width: 1200px)
 {
  .processData  {
    margin: 0px !important;  
    margin-left: 2.9rem;
  }
} */
.p-tag-value{
  font-weight: 500;
}
.p-button .p-button-label {
  font-family: 'Inter';
}
.p-inputtext{
  font-family: 'Inter';
}
/* .p-checkbox.p-checkbox-disabled {
  background: #f8fafc;
} */
/* .p-dialog .p-dialog-content{
  padding: 0px;
} */
.p-inputgroup-addon {
  background-color: white;
}
.p-datatable .p-datatable-tbody > tr:hover{
  background-color: #f8f9fa;
}
.p-datatable .p-datatable-tbody > tr:hover > td >button{
  background-color: #f8f9fa;
}
.p-calendar{
  width: 100%;
}
.edit_btn{
  background-color:transparent;
  border: none;
}
.delete_btn{
  color: red;
}
/* .logSummaryTable .p-datatable > .p-datatable-wrapper {
  overflow: visible !important;
} */
/* 
.processDataTable .p-datatable > .p-datatable-wrapper{
  overflow: hidden !important;
} */