@import '../../styles/sharedStyles';

.userHeading {
  color: $taylorFarmsPageHeadingColor;
}
.newuser_btn {
  font-weight: 600;
  padding: 12px;
}
.userDialog input {
  margin-bottom: 0.3rem;
  padding: 12px;
}
.submitUserHandler{
    padding: 12px 15px;
    font-weight: 600;
}
.errorMsg {
  color: red;
  display: flex;
  text-align: center;
}