@import '../../styles/sharedStyles';

.noBorder{
    border-left: none;
  }

  .primaryBtn {
    background-color: $taylorFarmsBrandYellow;
    color: black;
    border: none;
    width: 100%;
    height: 100%;
  }

  .primaryBtn span, .secondaryBtn span, .tirnaryButton span  {
    font-weight: 500;
}

.primaryBtn:focus,
.secondaryBtn:focus, .inputField:focus, .dropdown:focus {
  box-shadow: none;
}
.searchBar {
    text-align: end;
  }