@import '../../styles/sharedStyles';

.formLabel {
  color: $taylorFarmsFormLabelColor;
  font-size: rem(15);
}
.formLabelContainer {
  padding: rem(10) 0;
  height: 2.469rem;
}
.formControl {
  width: 100%;
}



.primaryBtn {
  background-color: $taylorFarmsBrandYellow;
  color: black;
  border: none;
}

.resetBtn {
  background-color: $taylorFarmsPageHeadingColor;
  color: white;
  border: none;
}

.copyBtn {
  background-color: $taylorFarmsPageHeadingColor;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 400;
}
.downloadIcon {
  background-color: white;
  color: $taylorFarmsPageHeadingColor;
  border: 0px solid;
  padding: 15px;
  cursor: not-allowed;
  
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 5px;
  font-size: 16px;
  // margin-top: 2.469rem;
}
.enableDownloadIcon {
  color: Green;
  cursor: pointer;
}
.errorMsg {
  color: red;
  display: flex;
  text-align: center;
  margin: rem(10) 0;
}

.formLabel {
  color: $taylorFarmsMenuItemsColor;
  font-weight: normal;
}

.filterDropdown {
  margin: 0 rem(20);
  width: rem(193);
  height: rem(48);
}
.searchBar {
  text-align: end;
}
.noBorder{
  border-left: none;
}
.tirnaryBtn{
  background-color: white;
  border: $taylorFarmsPageHeadingColor solid rem(1);
  color: $taylorFarmsPageHeadingColor;
}
.tirnaryBtn:hover {
  background-color: $taylorFarmsLightGrayColor ;
}
.ellipses{
  background-color: transparent;
}
.ellipses .p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
  width: max-content !important;
}